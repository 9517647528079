/**
 *
 * Handles all payment API calls
 * @author Chad Watson
 *
 */

import { payServerApiInstance } from "apis/payServer";

const getBaseUrl = (path = "") => `/api/v1/${path}`;

export const getStripeKey = () =>
  payServerApiInstance.get(getBaseUrl("stripe_key"));

export const getCards = () => payServerApiInstance.get(getBaseUrl("cards"));

export const addCard = (stripeToken) =>
  payServerApiInstance.post(getBaseUrl("cards"), { stripe_token: stripeToken });

export const updateCard = (id, params) =>
  payServerApiInstance.put(getBaseUrl(`cards/${id}`), params);

export const deleteCard = (id) =>
  payServerApiInstance.delete(getBaseUrl(`cards/${id}`));
