import { vkApiInstance } from ".";

const getRandomChar = (min, max) =>
  String.fromCharCode(Math.floor(Math.random() * (max - min)) + min);

export const getRandomLetter = () =>
  Math.round(Math.random()) ? getRandomChar(65, 90) : getRandomChar(97, 122);

export const getPart = ({ systemId, number, authUserCode }) => {
  const url = `/v2/parts_catalog/${getRandomLetter()}${getRandomLetter()}-${systemId}-${number}/details${
    authUserCode ? `?auth_user_code=${authUserCode}` : ""
  }`;
  return vkApiInstance.get(url).then((response) => response.data);
};
