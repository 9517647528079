/**
 *
 * Handles all favorites API calls
 * @author Kyle Ellman
 *
 */
import { vkApiInstance } from "apis/vk";
import { waitForJobToFinish } from "apis/vk/jobs";

const getBaseUrl = panelId => `/1/panels/${panelId}/favorites`;
const getFavoriteUrl = (panelId, favoriteId) =>
  `${getBaseUrl(panelId)}/${favoriteId}`;

/**
 * Fetches all favorites
 * @param  {integer} panelId
 * @param  {boolean} refresh (optional)
 * @return {promise}
 */
export const getAll = ({ panelId, refresh }) =>
  refresh
    ? waitForJobToFinish(
        vkApiInstance.get(getBaseUrl(panelId), { params: { safe: false } })
      )
    : vkApiInstance.get(getBaseUrl(panelId), { params: { safe: true } });

/**
 * Updates a favorite
 * @param  {integer} panelId
 * @param  {integer} favoriteId
 * @param  {Object} favorite
 * @return {promise}
 */
export const update = ({ panelId, favoriteId, favorite }) =>
  waitForJobToFinish(
    vkApiInstance.put(getFavoriteUrl(panelId, favoriteId), { favorite })
  );

/**
 * Activates a favorite
 * @param  {integer} panelId
 * @param  {integer} favoriteId
 * @return {promise}
 */
export const activate = ({ panelId, favoriteId }) =>
  waitForJobToFinish(
    vkApiInstance.post(`${getFavoriteUrl(panelId, favoriteId)}/activate`)
  );

/**
 * Deletes a favorite
 * @param  {integer} panelId
 * @param  {integer} favoriteId
 * @return {promise}
 */
export const deleteFavorite = ({ panelId, favoriteId }) =>
  waitForJobToFinish(vkApiInstance.delete(getFavoriteUrl(panelId, favoriteId)));

/**
 * Creates a favorite
 * @param  {integer} panelId
 * @param  {object} favorite
 * @return {promise}
 */
export const create = ({ panelId, favorite }) =>
  waitForJobToFinish(vkApiInstance.post(getBaseUrl(panelId), { favorite }));
