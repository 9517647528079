export const ERROR_CODES = {
  PANEL_BUSY: 1,
  INVALID_REMOTE_KEY: 2,
  INVALID_ACCOUNT_NUMBER: 3,
  PANEL_NOT_SET_UP_TO_CONNECT_REMOTELY: 4,
  CONNECTION_REQUIRES_ENCRYPTION: 5,
  CELL_COM_NOT_SUPPORT_CONNECTION: 6,
  CONNECTION_TIMEOUT: 7,
  OPERATION_CANCELLED: 8,
  INVALID_USER_CODE: 9,
  SEND_DAILY_REPORT_NOT_SET_UP: 10,
  UNABLE_TO_DISARM: 101,
  NO_REMOTE_DISARMING: 102,
  INVALID_AREA_SELECTION: 103,
  BAD_ZONES: 104,
  CANNOT_CHANGE_OUTPUT: 105,
  NOT_AUTHORIZED_TO_ARM_SOME_AREAS: 107,
  BYPASS_NOT_AUTHORIZED_TO_ARM_SOME_AREAS: 108,
  FORCE_NOT_AUTHORIZED_TO_ARM_SOME_AREAS: 109,
  NOT_AUTHORIZED_TO_DISARM_SOME_AREAS: 110,
  PROFILE_NOT_AUTHORIZED_TO_DISARM_OUTSIDE_OF_SCHEDULE: 111,
  NOT_AUTHORIZED_TO_BYPASS_SOME_AREAS: 112,
  AREAS_WITH_PRIORITY_ZONES_NOT_ARMED: 113,
  UNUSED_ZONE: 119,
  ARMED_OR_FIRE_ZONE: 120,
  PRIORITY_ZONE: 121,
  UNKNOWN: null,
};
