/**
 *
 * Handles all locks API calls
 * @author Matt Shaffer
 *
 */
import { vkApiInstance } from "apis/vk";

const getBaseUrl = panelId => `/1/panels/${panelId}/lock_statuses`;

/**
 * Fetches all status of locks
 * @param  {integer} panelId
 * @return {promise}
 */
export const getAll = ({ panelId }) => vkApiInstance.get(getBaseUrl(panelId));

/**
 * Fetches a single lock's status
 * @param  {integer} panelId
 * @param  {integer} number
 * @return {promise}
 */
export const get = ({ panelId, number }) =>
  vkApiInstance.get(`${getBaseUrl(panelId)}/${number}`);

/**
 * Updates a lock's status
 * @param  {integer} panelId
 * @param  {integer} number
 * @param  {string} status
 * @return {promise}
 */
export const update = ({ panelId, number, status }) =>
  vkApiInstance.post(`${getBaseUrl(panelId)}/${number}`, { status });
