/**
 *
 * Handles all outputs API calls
 * @author Matt Shaffer
 *
 */
import { vkApiInstance } from "apis/vk";
import { waitForJobToFinish } from "./jobs";

const getBaseUrl = (panelId) => `/1/panels/${panelId}/output_statuses`;

/**
 * Fetches all statuses of outputs
 * @param  {integer} panelId
 * @param  {boolean} refresh (optional)
 * @return {promise}
 */
export const getTracked = ({ panelId, refresh }) =>
  waitForJobToFinish(
    vkApiInstance.get(getBaseUrl(panelId), {
      params: { refresh, tracked_only: "true" },
    })
  );

export const getDoors = ({ panelId, refresh, trackedOnly = true }) =>
  waitForJobToFinish(
    vkApiInstance.get(getBaseUrl(panelId), {
      params: { refresh, tracked_only: trackedOnly, include_doors: "true" },
    })
  );

export const getAll = ({ panelId, refresh }) =>
  waitForJobToFinish(
    vkApiInstance.get(getBaseUrl(panelId), { params: { refresh } })
  );

/**
 * Fetches status of outputs of a single output
 * @param  {integer} panelId
 * @param  {integer} number
 * @return {promise}
 */
export const get = ({ panelId, number }) =>
  waitForJobToFinish(
    vkApiInstance.get(`${getBaseUrl(panelId)}/${number}`, {
      params: { tracked_only: "true" },
    })
  );

/**
 * Updates an output
 * @param  {string} panelId
 * @param  {integer} number
 * @param  {string} status
 * @param  {string} type
 * @return {promise}
 */
export const update = ({ panelId, number, status, type }) =>
  waitForJobToFinish(
    vkApiInstance.post(`${getBaseUrl(panelId)}/${number}`, {
      panelId,
      number,
      status,
      type,
      tracked_only: "true",
    })
  );

/**
 * Refreshes system outputs.
 *
 * @param  {string} panelId, legacy panel id of the panel
 * @return {promise}
 */
export const refresh = (panelId) =>
  waitForJobToFinish(vkApiInstance.get(`/1/panels/${panelId}/outputs/refresh`));
