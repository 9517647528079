/**
 *
 * Handles all profiles API calls
 * @author Kyle Ellman, Chad Watson
 *
 */
import { vkApiInstance, getV2Resource } from "apis/vk";
import { waitForJobToFinish } from "apis/vk/jobs";

const getBaseUrl = systemId => `/v2/panels/${systemId}/profiles`;
const getProfileUrl = (systemId, profileNumber) =>
  `${getBaseUrl(systemId)}/${profileNumber}`;

/**
 * Fetches all statuses of output options
 * @param  {string} systemId
 * @param  {boolean} refresh (optional)
 * @return {promise}
 */
export const getAll = async ({ systemId, refresh }) =>
  getV2Resource({
    baseUrl: getBaseUrl(systemId),
    refresh,
    getAllResults: true
  });
/**
 * Creates a profile and waits for job to finish
 * @param  {string} systemId
 * @param  {object} profile hash of new profile properties
 * @return {promise}
 */
export const create = ({ systemId, profile }) =>
  waitForJobToFinish(vkApiInstance.post(getBaseUrl(systemId), { profile }));

/**
 * Updates a profile and waits for job to finish
 * @param  {string} systemId
 * @param  {integer} profileNumber
 * @param  {object} profile hash of new profile properties
 * @return {promise}
 */
export const update = ({ systemId, profileNumber, profile }) =>
  waitForJobToFinish(
    vkApiInstance.put(getProfileUrl(systemId, profileNumber), { profile })
  );

/**
 * Deletes a profile and waits for job to finish
 * @param  {string} systemId
 * @param  {integer} profileNumber
 * @return {promise}
 */
export const destroy = ({ systemId, profileNumber }) =>
  waitForJobToFinish(
    vkApiInstance.delete(getProfileUrl(systemId, profileNumber))
  );
