/**
 *
 * Handles all users API calls
 * @author Matt Shaffer
 *
 */
import { vkApiInstance } from "apis/vk";
import { getAllResults } from "./paging";

const baseUrl = {
  users: userId => `/v2/users/${userId}`,
  customers: customerId => `/v2/customers/${customerId}/users`
};

/**
 * Fetches all users for a given customer
 * @param  {string} customerId
 * @param  {number} page
 * @param  {number} amount
 * @return {promise}
 */
export const getAll = ({ customerId }) =>
  getAllResults({
    apiCall: params => vkApiInstance.get(baseUrl.customers(customerId), params)
  });

export const update = ({ userId, user }) =>
  vkApiInstance.put(baseUrl.users(userId), {
    user
  });

export const create = ({ customerId, user }) =>
  vkApiInstance.post(baseUrl.customers(customerId), { user });

export const resetAccess = ({ userId }) =>
  vkApiInstance.post(`${baseUrl.users(userId)}/reset_access`);

export const destroy = ({ userId }) =>
  vkApiInstance.delete(baseUrl.users(userId));
