import React from "react";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import { withRouter } from "react-router-dom";
import { makeSuspendable } from "utils/suspense";
import { Helmet } from "react-helmet";

export const translations = {
  en: () => import("./translations/en.json"),
  es: () => import("./translations/es.json"),
  fr: () => import("./translations/fr.json"),
  nl: () => import("./translations/nl.json")
};

const fetchTranslations = hasIntl => async locale => {
  // Polyfills Intl for browsers that don't support it
  if (!hasIntl) {
    await import("intl");
  }

  return translations[locale]().then(messages =>
    Object.keys(messages).reduce(
      (result, key) => ({
        ...result,
        [messages[key].id]:
          messages[key].message || messages[key].defaultMessage
      }),
      {}
    )
  );
};

const locale =
  window.navigator.language in translations ? window.navigator.language : "en";

const resource = makeSuspendable(
  fetchTranslations(typeof window.Intl !== "undefined")(locale)
);

function LanguageProvider({ location, children }) {
  return (
    <>
      <Helmet htmlAttributes={{ lang: locale }} />
      <IntlProvider
        key={locale}
        locale={locale}
        messages={resource.read()}
        // This component blocks some route changes unless we pass the route location to it
        location={location}
      >
        {children}
      </IntlProvider>
    </>
  );
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default withRouter(LanguageProvider);
