/**
 *
 * Handles all areas API calls
 * @author Kyle Ellman
 *
 */
import { getV2Resource, vkApiInstance } from "apis/vk";
import { waitForJobToFinish } from "apis/vk/jobs";

const getBaseUrl = systemId => `/v2/panels/${systemId}/area_informations`;
const areaUrl = (systemId, number) => `${getBaseUrl(systemId)}/${number}`;

/**
 * Fetches all areas
 * @param  {integer} systemId
 * @param  {boolean} refresh (optional)
 * @return {promise}
 */
export const getAll = ({ systemId, refresh }) =>
  getV2Resource({
    baseUrl: getBaseUrl(systemId),
    getAllResults: true,
    refresh
  });

export const update = ({ systemId, number, area }) =>
  waitForJobToFinish(
    vkApiInstance.put(areaUrl(systemId, number), { area_information: area })
  );
