/**
 *
 * Handles all paging requests
 * @author Matt Shaffer
 *
 */

export const DEFAULT_PAGE_SIZE = 100;

/* eslint-disable camelcase */
export const getAllResults = async (
  { apiCall, params = {}, page = 1, page_size = DEFAULT_PAGE_SIZE },
  data = []
) => {
  const response = await apiCall({ params: { ...params, page, page_size } });
  if (response.data.length === page_size) {
    return getAllResults(
      { apiCall, params, page: page + 1, page_size },
      data.concat(response.data)
    );
  }

  return { ...response, data: data.concat(response.data) };
};
