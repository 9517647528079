/**
 *
 * Handles all barrier operator API calls
 * @author Matt Shaffer
 *
 */

import { vkApiInstance } from "apis/vk";

const getBaseUrl = panelId => `/1/panels/${panelId}/barrier_operator_statuses`;

/**
 * Fetches status of a single barrier operator
 * @param  {integer} panelId
 * @param  {integer} number
 * @return {promise}
 */
export const get = ({ panelId, number }) =>
  vkApiInstance.get(`${getBaseUrl(panelId)}/${number}`);

/**
 * Fetches all status of barrier operators
 * @param  {integer} panelId
 * @return {promise}
 */
export const getAll = ({ panelId }) => vkApiInstance.get(getBaseUrl(panelId));

/**
 * Updates a barrier operator's level
 * @param  {integer} panelId
 * @param  {integer} number
 * @param  {string} status
 * @return {promise}
 */
export const update = ({ panelId, number, status }) =>
  vkApiInstance.post(`${getBaseUrl(panelId)}/${number}`, { status });
