/**
 *
 * Handles all thermostats API calls
 * @author Matt Shaffer
 *
 */
import { vkApiInstance } from "apis/vk";

const getBaseUrl = panelId => `/1/panels/${panelId}/thermostat_statuses`;

/**

 * Fetches all status of thermostats
 * @param  {integer} panelId
 * @return {promise}
 */
export const getAll = ({ panelId }) => vkApiInstance.get(getBaseUrl(panelId));

/**
 * Fetches a single thermostat's status
 * @param  {integer} panelId
 * @param  {integer} number
 * @return {promise}
 */
export const get = ({ panelId, number }) =>
  vkApiInstance.get(`${getBaseUrl(panelId)}/${number}`);

/**
 * Updates a thermostat's status
 * @param  {object}
 * @return {promise}
 */
export const update = ({
  panelId,
  number,
  fanMode,
  mode,
  setpointCooling,
  setpointHeating
}) => {
  const params = {};
  if (setpointCooling) params.setpoint_cooling = setpointCooling;
  if (setpointHeating) params.setpoint_heating = setpointHeating;
  if (fanMode) params.fan_mode = fanMode;
  if (mode) params.mode = mode;

  return vkApiInstance.post(`${getBaseUrl(panelId)}/${number}`, params);
};
