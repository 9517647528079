/**
 *
 * Handles all system options API calls
 * @author Kyle Ellman
 *
 */
import { vkApiInstance, getV2Resource } from "apis/vk";
import { waitForJobToFinish } from "./jobs";

const getBaseUrl = systemId => `/v2/panels/${systemId}/system_options`;

/**
 * Fetches all system options
 * @param  {integer} systemId
 * @param  {boolean} refresh (optional)
 * @return {promise}
 */
export const get = ({ systemId, refresh }) =>
  getV2Resource({
    baseUrl: getBaseUrl(systemId),
    refresh
  });

/**
 * Fetches all system options
 * @param  {integer} systemId
 * @param  {object} options
 * @return {promise}
 */
export const update = ({ systemId, systemOptions }) =>
  waitForJobToFinish(
    vkApiInstance.put(getBaseUrl(systemId), { system_options: systemOptions })
  );
