/**
 *
 * Handles all doors API calls
 * @author Matt Shaffer
 *
 */

import { getV2Resource, vkApiInstance } from "apis/vk";
import { isNotNullOrUndefinedOrEmpty } from "common/utils/universal/function";
import { waitForJobToFinish } from "./jobs";

const getOutputsUrl = (panelId) => `1/panels/${panelId}/output_statuses`;
const getBaseDoorsV1Url = (panelId) => `/1/panels/${panelId}/doors/refresh`;
const getBaseDoorsV2Url = (controlSystemId) =>
  `/v2/panels/${controlSystemId}/doors`;

/**
 * Refreshes doors for a panel
 * @param  {string} panelId
 * @return {promise}
 */
export const refreshAll = ({ panelId }) =>
  waitForJobToFinish(vkApiInstance.get(getBaseDoorsV1Url(panelId)));

/**
 * Fetches all doors
 * @param  {integer} controlSystemId
 * @return {promise}
 */
export const getAll = ({ controlSystemId }) =>
  getV2Resource({
    baseUrl: getBaseDoorsV2Url(controlSystemId),
    getAllResults: true,
  });

/**
 * Updates a doors status
 * @param  {string} panelId
 * @param  {integer} number
 * @param  {string} status
 * @return {promise}
 */
export const update = ({ panelId, number, movement, userCode }) =>
  waitForJobToFinish(
    vkApiInstance.post(`${getOutputsUrl(panelId)}/${number}`, {
      type: "door",
      status: movement,
      ...(isNotNullOrUndefinedOrEmpty(userCode) && {
        auth_user_code: userCode,
      }), //If we have a user code, include auth_user_code in the body
    })
  );

/**
 * Locks down all doors and locks
 * @param  {integer} controlSystemId
 * @param  {string} user code
 * @return {promise}
 */
export const lockdown = ({ controlSystemId, userCode }) =>
  waitForJobToFinish(
    vkApiInstance.post(`${getBaseDoorsV2Url(controlSystemId)}/lockdown`, {
      auth_user_code: userCode,
    })
  );
