/**
 *
 * Handles all nodes API calls
 * @author Matt Shaffer
 *
 */
import { vkApiInstance } from "apis/vk";
import { waitForJobToFinish } from "apis/vk/jobs";

const getBaseUrl = panelId => `/1/panels/${panelId}/nodes`;

/**
 * Fetches all status of all nodes
 * @param  {integer} panelId
 * @param  {boolean} refresh (optional)
 * @return {promise}
 */
export const getAll = ({ panelId, refresh = false }) =>
  waitForJobToFinish(
    vkApiInstance.get(getBaseUrl(panelId), {
      params: {
        safe: !refresh
      }
    })
  );
