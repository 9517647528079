/**
 *
 * Contains oData API helper functions
 * @author Matt Shaffer
 *
 */

import { authInterceptor } from "apis/authInterceptor";
import axios from "axios";
import {
  odataEventHistories as odataEventHistoriesUrl,
  odataEventReports as odataEventReportsUrl,
  odata as odataUrl,
} from "config/urls";
import { identity } from "ramda";
import store from "store";
import { selectAuthTokenForCustomer } from "store/auth/selectors";
import {
  selectAuthTokenForSystem,
  selectDefaultAuthToken,
} from "store/common/selectors";
import { minutes } from "utils/dates";

export const odataApiInstance = axios.create({
  baseURL: odataUrl,
  timeout: minutes(1),
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
export const odataEventHistoriesApiInstance = axios.create({
  baseURL: odataEventHistoriesUrl,
  timeout: minutes(1),
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
export const odataEventReportsApiInstance = axios.create({
  baseURL: odataEventReportsUrl,
  timeout: minutes(1),
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const selectAuthTokenForUrl = (state, config) => {
  const [, customerId] =
    config.url.match(/\/api\/v1\/customers\(([0-9]+)\)/) ?? [];
  const [, , controlSystemId] =
    config.url.match(/\/api\/v1\/panels\(([0-9]+)\)/) ?? [];

  if (customerId) {
    return selectAuthTokenForCustomer(state, {
      customerId: Number(customerId),
    });
  } else if (controlSystemId) {
    return selectAuthTokenForSystem(state, {
      systemId: Number(controlSystemId),
    });
  } else {
    return selectDefaultAuthToken(state);
  }
};

const odataRequestInterceptor = (config) => {
  const state = store.getState();
  const authToken = selectAuthTokenForUrl(state, config);

  // Only assign Authorization if it doesn't exist
  if (authToken && !config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  return { ...config };
};

odataApiInstance.interceptors.request.use(odataRequestInterceptor);
odataApiInstance.interceptors.response.use(identity, authInterceptor);

odataEventHistoriesApiInstance.interceptors.request.use(
  odataRequestInterceptor
);
odataEventHistoriesApiInstance.interceptors.response.use(
  identity,
  authInterceptor
);

odataEventReportsApiInstance.interceptors.request.use(odataRequestInterceptor);
odataEventReportsApiInstance.interceptors.response.use(
  identity,
  authInterceptor
);
