/**
 *
 * Handles all events API calls
 * @author Matt Shaffer
 *
 */

import { odataEventHistoriesApiInstance } from "apis/odata";
const getBaseUrl = (panelId) => `/api/v1/panels(${panelId})/event_histories`;

/**
 * Fetches all latest events from a system
 * @param  {string} panelId, id of the panel
 * @return {promise}
 */
export const get = (panelId) => {
  const options = {
    params: {
      $orderby: "event_at desc",
    },
  };
  return odataEventHistoriesApiInstance.get(getBaseUrl(panelId), options);
};

/**
 * Fetches olders events than the given event id
 * @param  {string} panelId, id of the panel
 * @param  {integer} oldestEventId
 * @return {promise}
 */
export const getOlderEvents = (panelId, oldestEventId) => {
  const options = {
    params: {
      $orderby: "event_at desc",
      $filter: `event_id lt ${oldestEventId}`,
    },
  };
  return odataEventHistoriesApiInstance.get(getBaseUrl(panelId), options);
};

/**
 * Fetches newer events than the given event id
 * @param  {string} panelId, id of the panel
 * @param  {integer} oldestEventId
 * @return {promise}
 */
export const getNewEvents = (panelId, newestEventId) => {
  const options = {
    params: {
      $orderby: "event_at asc",
      $filter: `event_id gt ${newestEventId}`,
    },
  };
  return odataEventHistoriesApiInstance.get(getBaseUrl(panelId), options);
};
