/**
 *
 * Handles all zone status API calls
 * @author Tyler Torres
 *
 */
import { vkApiInstance } from "apis/vk";
import { waitForJobToFinish } from "apis/vk/jobs";
import { getAllResults } from "./paging";

const getBaseUrl = panelId => `/v2/panels/${panelId}/zone_statuses`;

export const getAll = panelId =>
  getAllResults({
    apiCall: params => vkApiInstance.get(getBaseUrl(panelId), params)
  });

export const refresh = async panelId => {
  await waitForJobToFinish(
    vkApiInstance.post(
      `${getBaseUrl(panelId)}/refresh?include_24_hour_zones=true`
    )
  );
  return getAll(panelId);
};
