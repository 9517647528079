/**
 *
 * Handles all light API calls
 * @author Matt Shaffer
 *
 */
import { vkApiInstance } from "apis/vk";

const getBaseUrl = panelId => `/1/panels/${panelId}/light_statuses`;

/**
 * Fetches all status of lights
 * @param  {integer} panelId
 * @return {promise}
 */
export const getAll = ({ panelId }) => vkApiInstance.get(getBaseUrl(panelId));

/**
 * Fetches status of a single light
 * @param  {integer} panelId
 * @param  {integer} number
 * @return {promise}
 */
export const get = ({ panelId, number }) =>
  vkApiInstance.get(`${getBaseUrl(panelId)}/${number}`);

/**
 * Updates a lights level
 * @param  {integer} panelId
 * @param  {integer} number
 * @param  {string} level
 * @return {promise}
 */
export const update = ({ panelId, number, level }) =>
  vkApiInstance.post(`${getBaseUrl(panelId)}/${number}`, { level });
