/**
 *
 * Handles all panel API calls
 * @author Matt Shaffer
 *
 */
import { vkApiInstance } from "apis/vk";
import { waitForJobToFinish } from "./jobs";

const V1_BASE_API_URL = "/1/panels";
const V2_BASE_API_URL = "v2/panels";

/**
 * Fetches the panels of a customer
 * @param  {object} queryParams, the api call by default will use the cached panels, but
 *                  if clearCache is true, then the api call will clear the API cache
 * @return {promise}
 */
export function getAll({ clearCache = false } = {}) {
  const params = { safe: !clearCache };
  return vkApiInstance.get(`${V1_BASE_API_URL}/discover`, { params });
}

export const get = panelId =>
  vkApiInstance.get(`${V1_BASE_API_URL}/${panelId}`);

/**
 * Gets the armed status of the panel, also has the side effect of starting a connection to a panel
 * @param  {object} [legacyPanelId: string, userCode: string]
 * @return {promise}
 */
export const getArmedStatus = ({ legacyPanelId, userCode }) => {
  const params = { auth_user_code: userCode || undefined };
  return waitForJobToFinish(
    vkApiInstance.get(`${V1_BASE_API_URL}/${legacyPanelId}/armed_status`, {
      params
    })
  );
};

export const arm = async ({
  legacyPanelId,
  areas,
  userCode,
  badZones,
  instant = false
}) => {
  const params = {
    auth_user_code: userCode || undefined,
    bad_zones: badZones,
    instant: instant ? "1" : "0",
    areas
  };
  const response = await waitForJobToFinish(
    vkApiInstance.post(`${V1_BASE_API_URL}/${legacyPanelId}/arm`, params)
  );

  if (response.data.error_code) {
    throw response;
  }

  return response;
};

export const disarm = ({ legacyPanelId, areas }) => {
  const params = { areas };
  return waitForJobToFinish(
    vkApiInstance.post(`${V1_BASE_API_URL}/${legacyPanelId}/disarm`, params)
  );
};

/**
 * Disconnects the app from the current panels session that it has established
 * @param  {object} [legacyPanelId: string, userCode: Integer]
 * @return {promise}
 */
export const disconnect = ({ id, userCode }) =>
  vkApiInstance.post(`${V2_BASE_API_URL}/${id}/disconnect`, {
    auth_user_code: userCode
  });

/**
 * Resets system after a lockdown
 * @param  {integer} controlSystemId
 * @param  {string} user code
 * @return {promise}
 */
export const sensorReset = ({ controlSystemId, userCode }) =>
  waitForJobToFinish(
    vkApiInstance.post(`${V2_BASE_API_URL}/${controlSystemId}/reset_sensors`, {
      auth_user_code: userCode
    })
  );
