/**
 *
 * Handles all door status API calls
 * @author Matt Shaffer
 *
 */

import { odataApiInstance } from "apis/odata";
const getBaseUrl = (panelId) =>
  `/api/v1/panels(${panelId})/vk.GetDoorStatuses()`;

/**
 * Fetches the last known status of a 734 door
 * @param  {string} systemId, id of the control system
 * @param  {integer} doorNumber
 * @return {promise}
 */
export const getRealtimeStatus = (systemId, doorNumber) => {
  const options = {
    params: {
      $filter: `record_number eq '${doorNumber}'`,
      $select: "record_number, status",
    },
  };

  return odataApiInstance.get(getBaseUrl(systemId), options);
};

export const getAllRealtimeStatuses = (systemId) => {
  const options = {
    params: {
      $select: "record_number, status",
    },
  };

  return odataApiInstance.get(getBaseUrl(systemId), options);
};
