/**
 *
 * Handles all zwave helper functions
 * @author Matt Shaffer
 *
 */

import { vkApiInstance } from "apis/vk";
import { waitForJobToFinish } from "./jobs";

const devicesUrl = panelId => `1/panels/${panelId}/zwave_devices`;

export const add = (panelId, name) =>
  vkApiInstance.post(devicesUrl(panelId), { device_name: name });

export const remove = panelId => vkApiInstance.delete(devicesUrl(panelId));

export const cancel = panelId =>
  vkApiInstance.post(`1/panels/${panelId}/cancel`);

export const rename = (panelId, number, name) =>
  waitForJobToFinish(
    vkApiInstance.put(`${devicesUrl(panelId)}/${number}`, { device_name: name })
  );
