/**
 *
 * Handles all XR schedules API calls
 * @author Chad Watson, Kyle Ellman
 *
 */
import { getV2Resource, vkApiInstance } from "apis/vk";
import { waitForJobToFinish } from "apis/vk/jobs";

const getBaseUrl = (systemId) => `/v2/panels/${systemId}/time_schedules`;
const scheduleUrl = (systemId, number) => `${getBaseUrl(systemId)}/${number}`;
const scheduleUseUrl = (systemId, number) =>
  `${scheduleUrl(systemId, number)}/associations`;

/**
 * Fetches all schedules
 * @param  {integer} systemId
 * @param  {boolean} refresh (optional)
 * @return {promise}
 */
export const getAll = ({ systemId, refresh }) =>
  getV2Resource({
    baseUrl: getBaseUrl(systemId),
    refresh,
    getAllResults: true,
  });

/**
 * Creates a schedule
 * @param  {integer} systemId
 * @param  {object} schedule
 * @return {promise}
 */
export const create = ({ systemId, schedule }) =>
  waitForJobToFinish(
    vkApiInstance.post(getBaseUrl(systemId), { time_schedule: schedule })
  );

/**
 * Updates a schedule
 * @param  {integer} systemId
 * @param  {integer} number
 * @param  {object} schedule
 * @return {promise}
 */
export const update = ({ systemId, number, schedule }) =>
  waitForJobToFinish(
    vkApiInstance.patch(scheduleUrl(systemId, number), {
      time_schedule: schedule,
    })
  );

/**
 * Deletes a schedule
 * @param  {integer} systemId
 * @param  {integer} number
 * @return {promise}
 */
export const destroy = ({ systemId, number }) =>
  waitForJobToFinish(vkApiInstance.delete(scheduleUrl(systemId, number)));

/**
 * Fetches all schedule uses
 * @param  {integer} systemId
 * @param  {integer} number
 * @return {Object  }
 **/
export const getUses = ({ systemId, number }) =>
  getV2Resource({
    baseUrl: scheduleUseUrl(systemId, number),
  });
