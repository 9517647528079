/**
 *
 * Handles all onDemand API calls
 * @author Chad Watson
 *
 */

import { jobsApiInstance } from "apis/jobs";

const getBaseUrl = (path = "") => `/api/v1/pod${path}`;

export const getById = id =>
  jobsApiInstance.get(getBaseUrl(`/PanelOD/panel/${id}`));

export const purchaseDays = json =>
  jobsApiInstance.post(getBaseUrl("/Purchases"), json);

export const makeEvent = json =>
  jobsApiInstance.post(getBaseUrl("/Events"), json);

export const updateEvent = json =>
  jobsApiInstance.put(getBaseUrl(`/Events/${json.Id}`), json);
